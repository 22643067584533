@import '../../colors';

.Dropdown {
  visibility: hidden;
  pointer-events: none;
  height: 0;
  overflow: hidden;

  &._active {
    visibility: visible;
    pointer-events: auto;
    height: auto;
  }
}

.DropdownTitle {
  border: none;
  font-family: inherit;
  text-decoration: none;
  overflow: hidden;
  outline: none;
  cursor: pointer;
  background: none;
  text-align: left;
  font-weight: inherit;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $col-blue-d;

  &:hover {
    color: lighten($col-blue, 10%);
  }
  &:active,
  &:focus {
    color: $col-blue-d;
  }
}

.DropdownTitle__icon {
  display: flex;
  align-items: center;
  flex-basis: 24px;
  margin-left: 8px;
  margin-right: 8px;
  transition: transform 0.2s ease;
  transform-origin: center;
  svg {
    width: 100%;
    height: 100%;
  }
  &._active {
    transform: rotate(-180deg);
  }
}
