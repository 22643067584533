.PageTitle {
  display: flex;
  align-items: center;
  word-break: break-word;
  h2 {
    white-space: pre-line;
  }
}

.BackButton {
  font-family: inherit;

  height: 1.5rem;
  width: 1.5rem;
  margin-right: 1rem;
  cursor: pointer;
  transition: 0.2s;
  padding: 0;
  &:hover {
    opacity: 0.7;
  }
}