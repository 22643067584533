@import '../base';

.DefaultLayout {
  height: 100vh;
}

.Main {
  padding: var(--header-height) var(--padding-value) 3em calc(var(--padding-value) + var(--toolbar-width));
  background-color: #F5F6F7;
  min-height: 100%;
  box-sizing: border-box;
}

.InfralistMain {
  padding-left: var(--padding-value);
}

.LoginLayout {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  main {
    background-color: #F6F7F8;
  }
}

.Logo {
  position: absolute;
  display: flex;
  padding: 1.5rem;
  max-width: var(--toolbar-width);
  top: 0;
  left: 0;
}

.Icon {
  flex-shrink: 0;
  margin-right: rpx(16);
}

.Text {
  color: #909191;
  font-size: rpx(13);
  font-weight: bold;
  line-height: (16 / 13);
}

