.DatePickerRanger {
  display: flex;
  position: relative;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0 14px;
  margin-right: 20px;

  &._standard {
    align-items: flex-end;
    height: 65px;
    border: none;
  }

  &._outlined {
    align-items: center;
    height: 56px;
    border: 1px solid rgba(0, 0, 0, 0.23);
  }
}

.DatePickerRanger__label {
  position: absolute;
  left: 10px;
  height: 11px;
  padding: 0 5px;
  background-color: #F5F6F7;
  color: rgba(0, 0, 0, 0.54);
  font-size: 0.85em;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;

  &._standard {
    top: 0;
  }

  &._outlined {
    top: -6px;
  }
}

.DatePickerRanger__inputs {
  display: flex;
  flex-direction: row;
}

.DatePickerRanger__input {
  width: 115px;
}

.DatePickerRanger__clearButton {
  padding: 0;
}

.DatePickerRanger__inputContainer {
  margin-right: 10px;
  label {
    margin-right: 5px;
  }
  &:last-child {
    margin-right: 0;
  }
}
