.Label {
    font-weight: 500;
    line-height: 20px;
    color: #727582;
    margin-bottom: 8px;
    display: block;
  }

.sizeNormal {
    font-size: 14px;
}

.sizeMin {
    font-size: 12px;
}
