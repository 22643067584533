@import '../../../colors';

.description {
  margin-bottom: 24px;
  p {
    color: $col-black;
  }
  p + p {
    margin-top: 4px;
  }
}

.attention {
  p {
    color: $col-error;
    font-size: 16px;
  }
}
