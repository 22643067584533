@import '../../base';
@import '../../colors';

.Header {
  padding: 0 var(--padding-value) 0
    calc(var(--padding-value) + var(--toolbar-width));
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--col-white);
  box-shadow: 0 2px 5px 0 rgba(var(--col-black-rgb), 0.26),
    0 2px rpx(10) 0 rgba(var(--col-black-rgb), 0.16);
  z-index: 1;
  height: var(--header-height);
  position: absolute;
  width: 100%;
}

.Header__element {
  display: flex;
  align-items: center;
}

.Header__mail {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: $col-blue-d;
  position: relative;
}

.mail__logo {
  width: 28px;
  height: 28px;
  color: $col-gray-table;
}

.mail__count {
  position: absolute;
  width: 18px;
  height: 18px;
  margin-left: 12px;
  right: -5px;
  bottom: -4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: $col-white;
  background-color: $col-blue;
  border-radius: 50%;
}

.Avatar {
  margin-right: 1rem;
}

.CabCaption {
  font-size: rpx(12);
  line-height: (14 / 12);
}

.CabOffice {
  margin-bottom: rpx(4);
  font-weight: bold;
}

.CabWorker {
  font-weight: 300;
}

.SearchField {
  flex-basis: 30%;
  margin-left: auto;
}
