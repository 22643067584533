@import '../../../base';
@import '../../../colors';

.header {
  padding: 0 var(--padding-value);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--col-white);
  box-shadow: 0 2px 5px 0 rgba(var(--col-black-rgb), 0.26),
    0 2px rpx(10) 0 rgba(var(--col-black-rgb), 0.16);
  z-index: 3;
  height: var(--header-height);
  position: absolute;
  width: 100%;
  min-width: 768px;
}

.right {
  margin-left: auto;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 6;
}

.historyButton {
  margin-right: 16px;
}

.selectWrapper {
  position: relative;
  z-index: 10;
}

.selectLabel {
  font-size: 12px;
  line-height: 16px;
  display: inline-block;
  background: #fff;
  position: absolute;
  left: 8px;
  top: -8px;
  z-index: 5;
  font-weight: 400;
  box-shadow: 0 -4px 10px 6px white;
  padding: 0 4px;
  border-radius: 7px;
}

.select {
  width: 400px;
  height: 36px;
  position: relative;
  z-index: 2;
}
