@import './../../../colors';

.AddFieldContainer {
  text-align: right;
}

.FileLink {
  color: #00f;
  text-decoration: none;
}

.FileLink:hover {
  text-decoration: underline;
}

.wrapper {
  display: flex;
  align-items: center;
}

.progressBar {
  width: 100%;
}

.textInputStyle {
  margin-bottom: 0;
}

.cellStyle {
  vertical-align: middle;
}

.documentNameCell {
  vertical-align: middle;
}

.documentBoxStyle {
  padding: 15px 0;
  display: flex;
  flex-direction: column;
}

.addLinkButton {
  max-width: 180px;
}

.gutterBottomStyle {
  margin-bottom: 5px;
}

.externalLink {
  text-decoration: underline;
  cursor: pointer;
}

.filePropCaptionStyle {
  font-weight: 500;
}

.linkInputStyle {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 15px;
}

.documentLinkBoxStyle {
  padding: 15px 0;
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.downloadButtonModal {
  display: flex;
}

.downloadButtonModal__content {
  position: relative;
  width: 400px;
  margin: auto;
  padding: 24px;
  text-align: center;
  border-radius: 24px;
  background-color: $col-white;
  outline: none;
}
