.InputGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
  padding: 1.5rem;
  border-radius: 3px;
  background-color: var(--col-white);

  fieldset {
    padding-right: 2rem;
    border: 1px solid rgba(var(--col-light-blue-rgb), 0.2);
  }

  input:disabled + fieldset {
    border: none;
  }
}

.InputGroupTitle {
  margin: 0 -1.5rem;
  margin-bottom: 1.5rem;
  padding: 0 2.5rem 1rem;
  border-bottom: 1px solid #e4e8eb;
}

.Field {
  margin-bottom: 1.5rem;
  margin-top: 0;
  &:last-child {
    margin-bottom: 0;
  }
  & + p {
    margin-top: -1.5em;
  }
}

.Field_fullWidth {
  width: 100%;
}

.TextInput {
  max-width: 500px;
}

.TextInput_fullWidth {
  width: 100%;
}

.Input {
  display: flex;
  align-items: center;
}

.InputString {
  min-width: 300px;
  margin-right: 1rem;
}

.AutocompleteValueContainer {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  align-items: center;
  overflow: hidden;
}

.Paper {
  position: absolute;
  padding-bottom: 4px;
  padding-top: 4px;
  overflow-x: scroll;
  max-height: 600px;
  width: 100%;
  z-index: 2;
}

.NoOptionsMessage {
  display: block;
  padding: 0.5rem;
}

.SelectIcon {
  right: 1.5rem !important;
}

.CurrencyDisplayContainer {
  position: relative;
  padding: 18.5px 14px;
  color: rgba(0, 0, 0, 0.38);
  font-size: 1rem;
  line-height: 1.1875em;
  margin-bottom: 0.5rem;
  &:last-child {
    margin-bottom: 0;
  }
}

.CurrencyDisplayPlaceholder {
  top: 0;
  left: 0;
  position: absolute;
  font-size: 75%;
  transform: translate(14px, -6px);
  height: 1.1875em;
}

.CurrencyDisplayValue {
  height: 1.1875em;
}

.CurrencyHelperText {
  margin-top: 8px;
  font-size: 0.75rem;
  min-height: 1em;
  color: var(--col-error);
  text-align: left;
  line-height: 1em;
  letter-spacing: 0.03333em;
}

.Option {
  overflow: hidden;
  text-overflow: ellipsis;
}

.List {
  z-index: 9000;
}

.KeyboardBtn {
  padding: 8px;
}

.TableSelectInput {
  width: 100%;
}

.TableAutocompleteWrapper {
  :global {
    .MuiInputBase-root,
    .MuiOutlinedInput-multiline {
      padding: 0;
    }
    .autocompleteInput fieldset {
      border: none;
      border-bottom: 1px solid rgba(0, 0, 0, 0.42);
      border-radius: 0;
    }
  }
}
