

.Spoiler {

}

.Spoiler__head {
  cursor: pointer;
}
.Spoiler__headTable {
  border: 1px solid transparent;
}
.Spoiler__headTable_error {
  border-color: #f44336;
}

.Spoiler__headTable_content {
  width: 100%;
  vertical-align: middle;
  padding-left: 0;
}
.Spoiler__headContent {
  margin-bottom: 0;
  background-color: transparent;

  > thead > tr > th {
    padding: 0;
    border-bottom: none;
  }
  > tbody {
    display: none;
  }
}

.Spoiler__error {
  //margin-left: 1rem;
  //margin-right: 1rem;
}