@import "src/colors";

.Tooltip {
  padding: 12px 16px;
  background-color: #FAE8BB;
  color: $col-black-gray;
  font-size: 12px;
  width: fit-content;
  max-width: fit-content;
  box-shadow: 0 8px 32px rgba(191, 196, 205, 0.4)
}

.Arrow {
  &:before {
    color: #FAE8BB;
  }
}
