.SignalText {
  margin-bottom: 16px;
  margin-top: 8px;
  font-size: 0.75rem;
  min-height: 1em;
  color: var(--col-error);
  text-align: left;
  line-height: 1em;
  letter-spacing: 0.03333em;
}

.warning {
  color: var(--col-yellow);
}