@import 'src/colors';

.wrapper {
  width: 460px;
  padding: 48px 40px 100px 40px;
  box-sizing: border-box;
  border-right: $col-light-gray;
  background: #fff;
  margin-left: -30px;
  overflow-y: auto;
}

.back {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.backTitle {
  color: $col-gray-table;
  font-size: 16px;
  font-weight: 500;
  margin-left: 10px;
  line-height: 20px;
}

.backButton {
  height: 1.5rem;
  width: 1.5rem;
}

.backIcon {
  color: $col-gray-table;
}

.topLine {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.title {
  font-size: 20px;
  line-height: 26px;
  color: #3f4752;
  font-weight: 500;
}

.edit {
  font-size: 14px;
  line-height: 20px;
  margin-left: auto;
  font-weight: 500;
  text-decoration: none;
  text-transform: capitalize;
}

.buttons {
  display: flex;
  margin-left: auto;
}

.smallGrayTitle {
  font-size: 12px;
  line-height: 16px;
  color: #9a9ca3;
  margin-bottom: 4px;
  word-break: break-all;
}

.smallBlackTitle {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #3f4752;
  margin-bottom: 4px;
  word-break: break-all;
}

.text {
  font-size: 14px;
  line-height: 20px;
  color: #3f4752;
  text-decoration: none;
  word-break: break-all;
}

.row {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.form {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 24px;
}

.field {
  color: #3f4752;
  margin-bottom: 0;
}

.input {
  font-size: 14px;
  line-height: 20px;
}

.fieldGroup {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 40px;
}

.link {
  text-decoration: underline;
  width: 380px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
}

.links {
  max-height: 130px;
  overflow: auto !important;
}
