@import "src/colors";

.root {
  display: flex;
  align-items: center;
  color: $col-dark-gray;
}

.statusSuccess {
  color: $col-green;
}

.statusError {
  color: $col-error;
}

.statusPoint {
  width: 6px;
  height: 6px;
  margin: 7px;
  border-radius: 50%;
  background-color: currentColor;
}

.statusText {
  font-size: 14px;
}
