main > .Loader {
  margin: 0 auto;
  margin-top: 1rem;
  display: block;
}

.Loader {
}

.LoaderCentered {
  margin: 0.5rem auto;
}