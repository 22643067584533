@import '../../../colors';

.question {
  vertical-align: middle;
  width: 50%;
}

.questionCell {
  width: 50%;
}

.title {
  font-weight: 700;
}

.headerCell {
  td,
  tr {
    color: $col-black !important;
  }
}

.valueCell {
  width: 25%;
}

.description {
  color: $col-black;
  p + p {
    margin-top: 4px;
  }
}
