@import '../../../../colors';

.root {
  border-top: none;
  border-left: none;
  border-right: none;
  padding: 48px 40px 16px 40px;
}

.backContainer {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.backTitle {
  color: $col-gray-table;
  font-size: 16px;
  font-weight: 500;
  margin-left: 10px;
  line-height: 20px;
}

.searchTitle {
  color: $col-black-gray;
  font-size: 20px;
  line-height: 26px;
  font-weight: 500;
  margin-bottom: 12px;
}

.select {
  margin-bottom: 8px;
}
