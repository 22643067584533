@import '../../mixins.scss';

.Table {
  border-spacing: 0 0.5rem;
  border-collapse: separate;
}

.TableHeaderCell_thin {
  width: 1%;
}

.TableHeaderCell_wide {
  width: auto;
}

.TableBodyCell_thin {
  width: 1%;
}

.TableBodyCell_wide {
  width: auto;
}

.TableBodyCell_bold {
  font-weight: bold;
}

.TableBodyCell_break {
  overflow-wrap: break-word;
}

.TableHeaderCell {
  padding: 0.5rem 1rem 0.5rem 1rem;
  font-size: rpx(13);
  line-height: (19/13);
  border: none;
  color: var(--col-light-blue);
  opacity: 0.6;
  font-weight: 400;
  text-transform: uppercase;
}

.TableBodyRow {
  text-decoration: none;
  vertical-align: top;
  border-radius: 3px;
  background-color: var(--col-white);
  &:hover {
    cursor: pointer;
  }
}

.TableBodyCell {
  padding: 1.5rem 1rem 1.5rem;
  border: none;
  font-size: 1rem;
  line-height: 1.5;
  &:first-child {
    padding-left: 1rem;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  &:last-child {
    //padding: 1rem 1rem 1rem 1.5rem;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
}

.TableBodyActionsCell {
  max-width: initial;
  width: 1%;
}

.AddFieldContainer {
  display: flex;
  align-items: center;
  padding: 0 1rem;
}

.Button, .ButtonIcon {
  margin-right: 0.5rem;
}

.Button {
  flex-shrink: 0;
  margin-left: auto;
}

.SignalText {
  font-size: 0.75rem;
  line-height: 1.2;
  margin-top: 0.5rem;
  color: var(--col-error);
}

.EditableTable {
  :global {
    .MuiPaper-root {
      border: 1px solid transparent;
    }
    .MuiTableCell-root {
      padding: 20px 1rem 20px 1rem;
      vertical-align: top;
    }

    .MuiTableCell-head {
      position: static;
    }
  }
}
.EditableTable_editable {
  :global {
    .MuiTableCell-root {
      &:last-child {
        vertical-align: middle;
      }
    }
  }
}
.EditableTable_error {
  :global {
    .MuiPaper-root {
      border: 1px solid #f44336;
    }
  }
}

.EditableTable__error {
  margin-left: 1rem;
  margin-right: 1rem;
}

.TotalTable {
  margin-top: 15px;
}

.actions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
