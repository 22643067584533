@import "src/colors";

.link {
  position: relative;
}

.notReadLabel {
  position: absolute;
  right: -1px;
  top: -1px;
  background-color: $col-yellow;
  width: 6px;
  height: 6px;
  border-radius: 50%;
}

.icon {
  font-size: 12px;
  color: $col-gray-table;
}
