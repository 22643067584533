.call {
  padding: 14px;
  min-width: 100px;
  &:nth-child(5) {
    min-width: 140px;
  }
}

.callCaption {
  font-weight: 500;
}

.errorCell {
  padding: 0 !important;
}

.dataCell {
  border: none;
}