.root {
  display: flex;
  margin-bottom: 18px;
  flex-wrap: wrap;
}

.filters {
  display: flex;
}

.sum {
  font-size: 30px;
  font-weight: 500;
  margin-right: auto;
}

.select {
  margin-left: 16px;
  margin-bottom: 16px;
  min-width: 300px;
}
