.root {
  display: flex;
  margin-bottom: 18px;

  > div {
    margin-left: 16px;
    margin-bottom: 16px;
    min-width: 300px;
  }
}

.sum {
  font-size: 30px;
  font-weight: 500;
  margin-right: auto;
}
