.text {
  display: block;
}

.list {
  padding: 10px 0;
}

.accent {
  font-style: italic;
  font-weight: 500;
}

.gutterBottom {
  margin-bottom: 20px;
}

.table {
  td, th {
    padding: 10px;
  }

  a {
    margin-left: 4px;
  }
}

.table__bordered {
  border-collapse: collapse;

  td, th {
    border: 1px solid lightgray;
  }
}

.contentBlock {
  margin-bottom: 20px;
}