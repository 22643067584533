@import "src/colors";

.tableWrapper {
  overflow-x: auto;
}

.table {
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-grow: 1;
  background-color: $col-white;
}

.sortIcon {
  position: relative;
  width: 6px;
  height: 8px;
  margin-left: 10px;

  &:before, &:after {
    content: '';
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    position: absolute;
  }

  &:before {
    border-bottom: 3px solid #D1D0D8;
    top: 0;
  }

  &:after {
    border-top: 3px solid #D1D0D8;
    bottom: 0;
  }
}

.sortIconActiveAsc:before {
  border-bottom-color: $col-dark-gray;
}

.sortIconActiveDesc:after {
  border-top-color: $col-dark-gray;
}

.tableBody {
  min-height: 60px;
  position: relative;
}

.loadingBlock {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #ffffff63;
  overflow: hidden;
}

.tableRow {
  display: grid;
  min-height: 48px;
  width: 100%;
  border-bottom: 1px solid $col-gray;
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
}

.tableCell {
  position: relative;
  padding: 14px 16px;
  align-items: center;
  min-height: 48px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  color: $col-gray-table;

  &:global(._header) {
    display: flex;
    color: $col-black-gray;
    font-weight: 500;
    user-select: none;
  }
}

.menuCell {
  margin: 0;
  flex: 0 1 40px;
  padding: 0;
  justify-content: center;
}

.isClickable {
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
}

.emptyMessage {
  padding: 1rem;
  color: $col-gray-table;
}

.tableActionCell {
  width: 48px;
  padding: 14px 12px;
  position: relative;
  display: grid;
  grid-auto-flow: row;
  align-items: start;
  grid-gap: 8px;
  grid-template-rows: min-content;
  grid-template-columns: auto;
  justify-items: center;
  flex-shrink: 0;
}
