@import 'src/colors';

.tableRow {
  display: grid;
  min-height: 48px;
  width: 100%;
  border-bottom: 1px solid $col-gray;

  &:global(._no-border) {
    border-bottom: none;
  }
}

.tableForm {
  border-bottom: 1px solid $col-gray;
}

.tableFormActions {
  padding-left: 54px;
  padding-bottom: 6px;
}

.tableFormButton {
  font-size: 12px;

  & + & {
    margin-left: 10px;
  }
}

.isClickable {
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
}

.tableCell {
  position: relative;
  padding: 14px 16px;
  align-items: center;
  min-height: 48px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  color: $col-gray-table;
  word-break: break-all;

  &:global(._header) {
    display: flex;
    color: $col-black-gray;
    font-weight: 500;
    user-select: none;
  }
}

.tableActionCell {
  width: 48px;
  padding: 14px 12px;
  position: relative;
  display: grid;
  grid-auto-flow: row;
  align-items: start;
  grid-gap: 8px;
  grid-template-rows: min-content;
  grid-template-columns: auto;
  justify-items: center;
  flex-shrink: 0;
}
