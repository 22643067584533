@import './src/colors';

.addButtonIcon {
  margin-right: 0.5rem;
}

.actionLine {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: start;
  grid-gap: 1rem;
  margin-bottom: 1rem;
}

.hideCharacteristicsCheckbox {
  font-size: 14px;
}
