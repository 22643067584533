@import './../../../../colors';

.Panel {
  background: white;
  border-radius: 3px;
  padding: 14px 16px;
  margin-bottom: 0.5rem;
}

.Panel__title {
  color: var(--col-black);
  font-weight: bolder;
  font-size: 13px;
  line-height: (16 / 13);
  margin-bottom: 1rem;
}

.Entity {
  padding-bottom: 1rem;
  padding-top: 1rem;
  border-top: 1px solid lighten($col-light-blue, 40%);
  margin-left: 1rem;
}

.Entity__otherDocuments {
  margin-left: 1rem;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid lighten($col-light-blue, 40%);
}

.Entity__documents {
  margin-left: 1rem;
  margin-bottom: 1rem;
}

.Entity__information {
  color: $col-light-blue;
  margin-top: 0.5rem;
  font-size: 0.675rem;
}

.Entity__title {
  font-weight: bold;
}

.Entity__subtitle {
  font-size: 0.8rem;
}

.Category {
  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }
}

.Category__title {
  margin-bottom: 0.5rem;
}

.Category__photos {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: -0.5rem;
  align-items: flex-start;
  justify-content: flex-start;

  > * {
    margin: 0.5rem;
  }
}

.Category__photos._editMode {
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
}

.Photo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 200px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;

  > img {
    max-width: 200px;
    max-height: 200px;
  }
}

.PhotoEdit {
  display: flex;
  flex-direction: row;

  > *:not(:last-child) {
    margin-right: 16px;
  }
}

.PhotoEdit__status {
  min-width: 200px;
}

.PhotoSubtitle {
}

.PhotoSubtitle__subtitle {
  color: $col-light-blue;
  font-size: 0.675rem;
}

.cellStyle {
  vertical-align: middle;
}

.downloadButton {
  display: flex;
  justify-content: flex-end;
  margin-right: 16px;
}

.downloadButton__entity {
  display: flex;
  justify-content: flex-end;
  margin-right: 32px;
}

.downloadLinkButton {
  max-width: 180px;
}

.downloadLinkButton__photoCategory {
  justify-content: flex-start;
  margin-top: 12px;
}
