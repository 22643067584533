.Comment {
  padding: 16px 20px;
  border-radius: 8px;
  box-shadow: 0 8px 32px rgba(191, 196, 205, 0.16);
  background: #fff;
}

.Text {
  font-size: 14px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word
}

.CommentHeader {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.CommentName {
  font-weight: 500;
  color: #3F4752;
  margin-right: 12px;
}

.CommentDate {
  font-size: 12px;
  line-height: 16px;
  color: #9A9CA3;
}

.CommentIcons {
  margin-left: auto;
}

.Buttons {
  display: flex;
  margin-bottom: 24px;
  margin-top: 24px;
}

.Button {
  margin-right: 24px;
}

.Status {
  padding-left: 5px;
  margin-top: 5px;
  align-items: center;
  display: flex;
}

.Icon {
  margin-right: 10px;
}

.CommentText {
  white-space: pre-wrap;
}

.CollectedComment {
  display: flex;
}

.CommentNumber {
  word-break: keep-all;
  flex-grow: 0;
  flex-shrink: 0;
}
