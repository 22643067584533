@import 'src/colors';

.container {
  display: flex;
  margin-bottom: -42px;
  height: calc(100vh - 72px);
}

.info {
  width: 460px;
  padding: 48px 40px 100px 40px;
  box-sizing: border-box;
  border-right: $col-light-gray;
  background: #fff;
  margin-left: -30px;
  overflow-y: auto;

  @media (max-width: 1400px) {
    width: 400px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.back {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.backTitle {
  color: $col-gray-table;
  font-size: 16px;
  font-weight: 500;
  margin-left: 10px;
  line-height: 20px;
}

.backIcon {
  color: $col-gray-table;
}
