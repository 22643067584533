.Paper {
  min-width: 422px;
  border-radius: 3px;
  padding: 2em 3em 3em;
  background-color: var(--col-white);
}

.Form {
  display: flex;
  flex-direction: column;
}

.FormTitle {
  color: #0D1B35;
  font-size: 20px;
  font-weight: 500;
  line-height: (26 / 20);
  text-align: center;
}

.Input {
  font-size: 16px;
  line-height: (19 / 16);
}

.SubmitButton {
  margin-top: 3rem;
  padding: 1rem;
  font-size: 14px;
  line-height: (24 / 14);
}

.UserAgreementText {
  max-width: 280px;
  margin: 1.5rem auto 0;
  text-align: center;
  font-size: 14px;
  line-height: (16 / 14);
}

.OuterLink {
  margin-top: 1em;
  text-align: center;
}