.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 18px 0;
}

.titleGroup {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.title {
  margin: 0;
}

.BackButton {
  margin-right: 1rem;
}

.Description {
  display: flex;
}
