.container {
  // для того чтобы тултип в хедере не перекрывал меню
  z-index: 1501 !important;
}

.menuPaper {
  width: 200px;
  filter: drop-shadow(0px 8px 32px rgba(191, 196, 205, 0.4));
}

.menuItem {
  font-size: 12px;
}
