@import '../../mixins.scss';

.Drawer {
  grid-area: sidebar;
}

.Paper {
  width: var(--toolbar-width);
  background-color: var(--col-toolbar-bg);
}

.Logo {
  display: flex;
  align-items: center;
  padding-left: 1rem;
  color: var(--col-white);
  min-height: var(--header-height);
  box-shadow:
    0 2px 5px 0 rgba(var(--col-black-rgb),0.26),
    0 2px rpx(10) 0 rgba(var(--col-black-rgb),0.16);
}

.Icon {
  margin-right: rpx(16);
}

.Text {
  font-size: rpx(13);
  font-weight: bold;
  line-height: (16 / 13);
}

.SmallText {
  margin: 0;
  font-size: rpx(11);
  font-weight: 300;
  line-height: (16 / 11);
}

.Navigation {
  padding-top: 0;
}

.ListItem {
  padding: rpx(12) rpx(16);
  color: var(--col-light-blue);
  border-left: 3px solid transparent;
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-weight: normal;
  &:hover {
    border-left-color: var(--col-light-blue);
    color: var(--col-white);
    background-color: rgba(var(--col-light-blue-rgb), 0.10);
  }
}

.ListItemActive {
  border-left-color: var(--col-light-blue);
  color: var(--col-white);
  background-color: rgba(var(--col-light-blue-rgb), 0.10);
}

.ListItemTextRoot {
  margin: 0;
}

.ListItemTextPrimary {
  font-weight: 500;
  font-size: rpx(16);
  line-height: (19 / 16);
}

.ActualDate {
  margin: auto auto rpx(16) #{rpx(16+3)};
  color: var(--col-white);
}

.subItem {
  padding-left: 35px;
}

.active {
  border-left-color: var(--col-light-blue);
  color: var(--col-white);
  background-color: rgba(var(--col-light-blue-rgb), 0.10);
}