@import '../../../../colors.scss';

.gridCell {
  padding-left: 40px;
  padding-right: 40px;
  word-break: break-word;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.rowTitle {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: $col-gray-table;
  margin-bottom: 4px;
}

.rowSubTitle {
  font-weight: 500;
  margin-bottom: 4px;
}

.noValue {
  color: $col-gray-table;
}

.diffOld {
  background-color: rgba($col-red, 0.2);
}

.diffNew {
  background-color: rgba($col-green-bright, 0.2);
}
