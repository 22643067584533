@import "src/colors";

.smallGrayTitle {
  font-size: 12px;
  line-height: 16px;
  color: #9A9CA3;
  margin-bottom: 4px;
  word-break: break-word
}

.smallBlackTitle {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #3F4752;
  margin-bottom: 4px;
  word-break: break-word;
}

.text {
  font-size: 14px;
  line-height: 20px;
  color: #3F4752;
  text-decoration: none;
  word-break: break-word;
  white-space: pre-line;
}

.row {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}
