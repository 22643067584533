
.ExportHistoryPopup {

}

.Search {
  margin: 1em 0 2em;
}
.Search__input {
  width: 100%;
}

.HistoryItem {
  margin-bottom: 1.5em;

  &:last-child {
    margin-bottom: 0;
  }
}
.HistoryItem__date {
  margin-bottom: .5em;
  color: var(--col-gray-table);
}
.HistoryItem__name {
  margin-bottom: .5em;
}
.HistoryItem__hash {
  white-space: pre-wrap;
}