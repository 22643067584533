@import "src/colors";

.Header {
  height: 54px;
  display: flex;
  align-items: center;
  border-radius: 6px 6px 0 0;
  border-bottom: 1px solid $col-gray;
  padding: 0 16px 0 24px;
}

.HeaderTitle {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: $col-black-gray;
}

.New {
  margin-left: auto;
  padding: 5px 21px;
  color: #fff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  background: $col-yellow;
  height: 26px;
}

.Main {
  max-height: 360px;
  overflow-y: auto;
}

.loadingBlock {
  display: flex;
  overflow: hidden;
}

.Item {
  display: flex;
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}

.IndicatorWrapper {
  width: 36px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 21px;
}

.Indicator {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: $col-yellow;
}

.ItemContent {
  border-bottom: 1px solid $col-gray;
  width: calc(100% - 36px);
  padding: 15px 0 10px 0;
}

.ItemTitle {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: $col-black-gray;
  margin-bottom: 6px;
}

.ItemDescription {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: $col-black-gray;
  margin-bottom: 6px;
  word-break: break-word;
}

.ItemDate {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: $col-dark-gray;
}

.Footer {
  height: 48px;
  background: $col-gray;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $col-dark-gray;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 0 0 6px 6px;
  padding: 0 24px;
}
