@import '../../../../colors';

.root {
  border-top: none;
  border-left: none;
  border-right: none;
  padding: 16px 40px;
}

.active {
  background-color: #eef4ff;
}

.titleContainer {
  display: flex;
  align-items: center;
}

.title {
  margin-right: 8px;
}

.openButton {
  min-width: max-content;
  margin-left: auto;
  padding: 4px 12px;
  line-height: 20px;
}

.label {
  color: $col-gray-table;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.value {
  color: $col-black-gray;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.row {
  margin-top: 8px;

  > p:first-child {
    margin-bottom: 2px;
  }
}
