@import 'src/colors';

.Select {
  height: 36px;
}

.dropdownIndicator {
  margin: 5px;
  color: #ccc;

  &:hover {
    color: #999;
  }
}

.option {
  margin-top: 8px;
  background-color: $col-gray;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 4px 0 4px 12px;
  font-size: 12px;
  width: max-content;
  max-width: 100%;
}

.deleteIcon {
  box-sizing: content-box;
  padding: 2px 12px;
  width: 14px;
  min-width: 14px;
  cursor: pointer;

  &:hover {
    color: #444;
  }
}
