@import 'src/colors';

.InputWrapper {
  color: $col-black-gray;
  font-size: 14px;
  border-radius: 6px;
  width: 100%;
  outline: none;
  border: 1px solid #D1D0D8;
}

.Input {
  padding: 10px 12px;
  border-radius: 6px;
  font-size: 12px;
  line-height: 16px;
  min-height: 36px;
  box-sizing: border-box;
  background: #fff;
}

.InputMultiline {
  padding: 10px 12px;
}

.InputMultilineWrapper {
  padding: 0;
}

.Disabled {
  background: $col-gray;
}

.ErrorField {
  border: 1px solid $col-error;
}

.Error {
  color: $col-error;
  font-size: 12px;
  line-height: 16px;
  margin-left: 0;
}

.Label {
  display: none;
}

.Notched {
  display: none;
}

.Focus {
  border: 1px solid $col-blue;
}
