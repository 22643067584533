.AddFieldContainer {
  text-align: right;
  display: flex;
  padding-bottom: 15px;
  justify-content: flex-end;

  > *:not(:last-child) {
    margin-right: 0.5rem;
  }
}

.IconButton {
  padding: 0.5rem;
}

.Change__TableCell {
  width: auto;
}

.Total {
  font-weight: 500;
  text-align: right;
}
