.mailLogo {
  width: 28px;
  height: 28px;
  margin: 0 auto;
}

.Notification__contentTitle {
  padding: 16px;
  font-size: 13px;
  font-weight: bold;
  line-height: 1.23077;
}
.Notification__contentText {
  padding: 0 16px 16px;
}

.datePicker {
  width: 300px;
  margin-bottom: 1.5rem;
  margin-right: 16px;
  input {
    height: 36px;
  }
}