@import '../../../../colors.scss';

.root {
  position: relative;
  flex: 1;

  // Разделитель
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 1px;
    height: 100%;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.12);
    pointer-events: none;
  }
}

.loader {
  width: 100%;
  text-align: center;
  padding-top: 40px;
  background-color: $col-white;
  height: calc(100vh - var(--header-height));
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: max-content;
  gap: 24px;
  padding-top: 40px;
  background-color: $col-white;
  height: calc(100vh - var(--header-height));
  overflow-y: auto;

  &:after {
    content: '';
    padding-bottom: 40px;
  }
}

.title {
  font-size: 20px;
  line-height: 26px;
  font-weight: 500;
  word-break: normal;
}

.downloadButton {
  text-transform: none;
}

.gridCell {
  padding-left: 40px;
  padding-right: 40px;
  word-break: break-word;
}

.link {
  color: inherit;
  display: block;
}
