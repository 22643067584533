@import './../../colors';

.wrapper {
  background-color: #f5f6f7;
  height: 100vh;
  padding: 20px;
}

.FormHeader {
  left: 0 !important;
  width: 100% !important;
}

.questionCell {
  width: 50%;
}

.question {
  vertical-align: middle;
  width: 50%;
}
.addButton {
  display: flex;
  justify-content: center;
  align-items: center;
}

.addButton__title {
  font-size: 12px;
  margin-right: 4px;
}

.headerCell {
  td,
  tr {
    color: $col-black !important;
  }
}

.valueCell {
  width: 25%;
}

.buttonAsLink {
  border: none;
  background: transparent;
  font-family: inherit;
  font-size: 16px;
  color: $col-blue;

  &:hover {
    color: $col-blue-d;
    text-decoration: underline;
    cursor: pointer;
  }
  &:focus {
    outline: none;
  }
}

.buttons {
  display: flex;
  justify-content: flex-end;
}

.files {
  margin-top: 6px;
}

.annotation {
  color: $col-gray-table;
  margin-top: 16px;
  p + p {
    margin-top: 4px;
  }
}

.OrganizationsDataFields__table {
  margin-bottom: 20px;
}

.paragraphBlock {
  white-space: pre-line;
}
