@import "src/colors";

.Select {
  height: 36px;
}

.placeholder {
  color: #9a9ca3;
  font-size: 12px;
  line-height: 16px;
  display: inline-block;
  background: #fff;
  position: absolute;
  left: 8px;
  top: -8px;
  z-index: 5;
  font-weight: 400;
  box-shadow: 0 0 1px 1px white;
  padding: 0 4px;
  border-radius: 7px;
}
