@import "src/colors";

.Popup {
  min-width: 380px;
}

.Title {
  color: $col-black-gray;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  margin-bottom: 8px;
}

.Content {
  padding: 0 32px 38px 32px;
}

.Description {
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 32px;
  color: $col-black-gray;
}

.Header {
  display: flex;
  padding: 21px;
}

.Button {
  min-width: 120px;
  margin-right: 15px;
  width: fit-content;
}

.Close {
  margin-left: auto;
  cursor: pointer;
}

.Actions {
  display: flex;
}

.Children {
  margin-bottom: 40px;
}
