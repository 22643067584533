.SubTable {
  border-radius: 3px;
  margin-bottom: 0.5rem;
  background-color: var(--col-white);
  tr {
    vertical-align: top;
  }
  th, td {
    font-size: 13px;
    line-height: (16 / 13);
  }

  tr:last-child {
    td {
      border: none;
    }
  }

  > tbody > tr > td {
    word-break: break-word;

    > * {
      word-break: normal;
    }
  }

  &.SubTable_fixed {
    &._cols2 {
      > thead > tr > th,
      > tbody > tr > td {
        width: 50%;
        &:last-child:not(.SubTableHeader) {
          color: var(--col-gray-table);
        }
      }
      //td {
      //  width: 50%;
      //  &:last-child:not(.SubTableHeader) {
      //    color: var(--col-gray-table);
      //  }
      //}
    }
  }
}

.SubTable_edit {
  th, td {
    &:first-child {
      padding-left: 2.5rem;
    }
  }
  th, .SubTableHeader {
    font-size: 14px;
  }
}

.SubTableHeader {
  color: var(--col-black);
  font-size: 14px;
  line-height: (16 / 14);
  font-weight: bolder;
}

.SubTable__supHeading {
  color: var(--col-light-blue);
}
.SubTable__supHeading_withTitle {
  margin-bottom: 8px;
}