@import '../../../../colors';

.notificationContainer {
  position: relative;
}

.notificationButton {
  position: relative;
  width: 36px;
  height: 36px;
  background-color: transparent;
  box-shadow: none;
  margin-left: 20px;
}

.notificationsNotRead {
  position: absolute;
  right: 8px;
  top: 8px;
  width: 6px;
  height: 6px;
  background-color: $col-yellow;
  border-radius: 50%;
}

.notificationIcon {
  font-size: 24px;
  color: $col-dark-gray;
  transform-origin: 50% 4px;

  &:global(._animated) {
    animation: ring 1s ease-in-out 1s 1;
  }
}
.active {
  background-color: #d5d5d5;
}

@keyframes ring {
  0% { transform: rotate(0); }
  15% { transform: rotate(10deg); }
  30% { transform: rotate(-10deg); }
  45% { transform: rotate(8deg); }
  60% { transform: rotate(-8deg); }
  75% { transform: rotate(4deg); }
  85% { transform: rotate(-4deg); }
  92% { transform: rotate(2deg); }
  100% { transform: rotate(0); }
}

.popup {
  width: 360px;
  height: fit-content;
  border-radius: 6px;
  box-shadow: 0 8px 32px rgba(191, 196, 205, 0.16);
  background: #fff;
  position: absolute;
  top: 100%;
  right: 0;
  margin: 0;
  opacity: 0;
  transition: .3s ease;
  pointer-events: none;
  transform: translate(0, -20px);

  &:global(._opened) {
    opacity: 1;
    transform: translate(0, 0);
    pointer-events: all;
  }
}

.tooltipWrapper {
  display: flex;
}

.tooltipText {
  white-space: nowrap;
}
