.fixedContainer {
  position: static;
  width: 100%;
}
.fixed.fixedContainer {
  position: fixed;
  top: 0;
  left: var(--toolbar-width);
  width: calc(100% - (var(--toolbar-width)));
  z-index: 999;
  padding: 10px var(--padding-value);
  border-bottom: 1px solid rgba(var(--col-light-blue-rgb), 0.2);
  background: #ffffff;
  > div {
    margin-bottom: 0;
  }
}