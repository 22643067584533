.buttonArea {
  margin-top: -4px;
}

.tableMenuButton {
  width: 24px;
  height: 24px;
  padding: 0;

  &:hover {
    background: none;
  }
}

.tableMenuIcon {
  font-size: 16px;
}
