.Container {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.Button {
  flex-shrink: 0;
}

.ErrorText {
  margin-right: 1rem;
}

.Icon {
  margin-right: 0.5rem;
}