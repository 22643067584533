@function px($v) {
  @return $v / 16 * 1em;
}

@function rpx($v) {
  @return $v / 16 * 1rem;
}

@mixin buttonReset {
  padding: 0;
  border: none;
  font-family: inherit;
  text-decoration: none;
  overflow: hidden;
  outline: none;
  cursor: pointer;
  background: none;
  text-align: left;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
}