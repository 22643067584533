@import './../../../colors';

.wrapper {
  background-color: #F5F6F7;
  height: 100vh;
  padding: 20px;
}

.questionCell {
  width: 50%;
}

.title {
  font-weight: 700;
}

.valueCell {
  width: 25%;
}

.buttonAsLink {
  border: none;
  background: transparent;
  font-family: inherit;
  font-size: 16px;
  color: $col-blue;

  &:hover {
    color: $col-blue-d;
    text-decoration: underline;
    cursor: pointer;
  }
  &:focus {
    outline: none;
  }
}

.buttons {
  display: flex;
  justify-content: flex-end;
}