.Actions {
  margin-top: 24px;
}

.Button {
  margin-right: 15px;
}






