@import './../../../../colors';
.wrapper {
  display: flex;
  align-items: center;
}

.fileField {
  margin-bottom: 12px;
}

.progressBar {
  width: 100%;
}

.textInputStyle {
  margin-bottom: 0;
}

.cellStyle {
  vertical-align: middle;
}

.gutterBottomStyle {
  margin-bottom: 5px;
}

.filePropCaptionStyle {
  font-weight: 500;
}

.question {
  vertical-align: middle;
  width: 50%;
}

.files {
  :global .MuiTextField-root {
    min-width: 230px;
  }
  border: none;
  td {
    border: none;
  }
}

.cellFiles {
  tr:last-child td {
    border-bottom: 1px solid rgba(224, 224, 224, 1);
  }
}
