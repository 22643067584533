.BreadCrumbs {
  margin-top: 1rem;
}

.BreadCrumbFont {
  font-size: 12px;
  line-height: (16 / 12);
}

.linkAsSpan:hover {
  text-decoration: none;
}
