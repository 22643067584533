@import 'colors';

body {
  min-width: 768px;
}

p {
  margin: 0;
}

a.external,
.external {
  color: $col-blue;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: darken($col-blue, 20%);
  }
}
