.Container {
  background: #fff;
  height: 100%;
  padding: 32px 40px;
  margin-left: auto;
  margin-right: -2rem;

  @media (max-width: 1400px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.Title {
  font-size: 20px;
  line-height: 26px;
  font-weight: 500;
  margin-bottom: 24px;
}

.List {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 10px;
  margin-bottom: 40px;
}

.Item {
  padding: 12px;
  background: #fff;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
}

.ActiveItem {
  background: #F5F6F7;
}

.DownloadTitle {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  margin-bottom: 16px;
}
