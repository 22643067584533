.ErrorPage {
  width: 310px;
  height: calc(100vh - var(--header-height) - 3em);
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ErrorPage__text {
  font-size: 18px;
  font-weight: bold;
}

.ErrorPage__backLink {
  margin-top: 12px;
}
