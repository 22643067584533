.rowStyle {
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.sum {
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  min-width: 250px;
}

.inputStyle {
  margin-bottom: 0;
  min-width: 200px;
}

.info {
  font-size: .75rem;
  color: rgba(0, 0, 0, 0.38);
  font-style: italic;
}