.SearchForm {
  width: 400px;
  position: relative;
}

.SearchInput {
  width: 400px;
}

.SearchButton {
  border: none;
  background: none;
  position: absolute;
  top: 20px;

  &:focus {
    outline: none;
  }

  &:hover {
    cursor: pointer;
  }
}

.SearchIcon {
  &:hover {
    fill: #303f9f;
  }
}
