.Title {
  font-weight: 500;
  margin-bottom: 10px;
}
.NormalText {
  font-weight: 400;
}
.GutterBottom {
  margin-bottom: 20px;
}
.AddFileInputBox {
  display: flex;
  align-items: center;
}
.ssType {
  margin: -15px 0 20px 25px;
}

.ssType__caption {
  font-weight: 500;
}

.Button,
.ButtonIcon {
  margin-right: 0.5rem;
}

.Button {
  flex-shrink: 0;
  margin-right: auto;
}

.infralist {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.InfralistInfo__link {
  display: block;
  width: 100%;
}
