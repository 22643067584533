@import '../../colors';
@import '../../mixins.scss';

.FormHeaderContainer {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}
.FormHeaderEditing {
  //position: absolute;
  //bottom: 0;
  color: $col-error;
  font-size: 12px;
  line-height: 16px;
  //transform: translateY(50%);
}

.Link {
  text-transform: uppercase;
  color: var(--col-light-blue);
  font-weight: 500;
}

.FormHeaderActions {
  //display: flex;
  //flex-direction: row;
  display: grid;
  grid-auto-columns: min-content;
  grid-auto-flow: column;
  gap: 8px;
  margin-left: auto;
}