.warningSignal {
  margin: 0 0 25px 16px;
}

.selector > div > div {
  display: flex;
  align-items: center;
  height: 36px;
}

.selector > div > svg {
  display: none;
}

.approveButton {
  margin-left: 15px;
  margin-top: 16px;
}
