.CommentListContainer {
  padding: 40px 0 56px 0;
  width: 520px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow-y: auto;
  margin: 0 88px;

  @media (max-width: 1400px) {
    margin: 0 15px;
  }
}

.CommentList {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 24px;
  margin-bottom: 24px;
  overflow-y: auto;
}

.CommentField {
  margin-bottom: 20px;
}

.CommentButton {
  width: fit-content;
}

.Buttons {
  display: flex;
  margin-bottom: 24px;
}

.Button {
  margin-right: 24px;
}
