@import '../../../colors';
@import '../../../mixins.scss';

$buttonSize: 40px;

.DownloadButton {
  position: relative;
}

.DownloadButton__button {
  @include buttonReset;
  width: $buttonSize;
  height: $buttonSize;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: background-color 0.2s linear;

  &:hover {
    background-color: rgba($col-blue, 0.04);
  }
  &:focus,
  &:active {
    background-color: rgba($col-blue, 0.1);
  }
  svg {
    color: $col-blue;
    width: 24px;
    height: 24px;
  }
  &.DownloadButton__button_active {
    background-color: rgba($col-blue, 0.1);
  }
}

.DownloadButton__files {
  position: absolute;
  top: $buttonSize + 6px;
  display: flex;
  flex-direction: column;
  background-color: $col-light-darker;
  border-radius: 12px;
  overflow: hidden;
}

.DownloadButton__file {
  @include buttonReset;
  min-height: 57px;
  width: 224px;
  height: 100%;
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  outline: none;
  border-radius: 0;

  svg {
    color: $col-blue;
  }

  & + & {
    border-top: 1px solid darken($col-light-darker, 15%);
  }

  &:hover {
    background-color: darken($col-light-darker, 10%);
  }
  &:focus,
  &:active {
    background-color: darken($col-light-darker, 15%);
  }
}
