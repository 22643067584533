.Description {
  display: flex;
  flex-direction: column;
  white-space: pre-line;
}

.Description__Title {
  display: block;
  margin: 20px 0 5px;
  font-weight: 500;
}