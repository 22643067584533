.SortLabel {
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  &:hover {
    cursor: pointer;
    opacity: 0.5;

    .SortLabel__arrows {
      display: flex;
    }
  }
}
.SortLabel__arrows {
  position: absolute;
  right: 0;
  bottom: 0;
  display: none;
  align-items: flex-end;
  margin-left: 5px;
  &._active {
    display: flex;
  }
}
