.Description {
  margin-bottom: 20px;
}

.PcWrapper {
  padding: 15px 0;
}

.Caption {
  font-weight: 500;
  padding-bottom: 10px;
}

.DownloadTitle {

}

.DownloadButton {
  margin: 10px 0;
}

.lastTestDate {
  padding: 5px 0;
}