.Header__element {
  display: flex;
  align-items: center;
}

.Avatar {
  margin-right: 1rem;
}

.CabCaption {
  font-size: rpx(12);
  line-height: (14 / 12);
}

.CabOffice {
  margin-bottom: rpx(4);
  font-weight: bold;
}

.CabWorker {
  font-weight: 300;
}

.SearchField {
  flex-basis: 30%;
  margin-left: auto;
}
