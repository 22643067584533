.root {
  min-width: 460px;
  max-width: 460px;
  height: calc(100vh - var(--header-height));
  overflow-y: auto;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}

.paper {
  width: 100%;
  height: 100%;
  border-right: none;
}

.loading {
  padding-top: 16px;
  text-align: center;
}
