@import "src/colors";

.root {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-gap: 24px;
  justify-content: flex-start;
  margin: 45px 0 30px;
}

.title {
  color: $col-black-gray;
  font-size: 20px;
  line-height: 26px;
  font-weight: 500;
}

.versionsSelect {
  width: 350px;
}
