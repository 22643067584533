@function derive-rgb-values-from-hex($hex) {
  @return red($hex), green($hex), blue($hex);
}

$col-violet: rgb(71, 48, 122);
$col-blue: rgb(50, 80, 187);
$col-blue-d: rgb(13, 27, 53);
$col-blue-d2: rgb(8, 17, 34);
$col-blue-l: rgb(41, 151, 212);
$col-green: rgb(0, 142, 131);
$col-green-bright: rgb(37, 174, 117);
$col-green-light: rgb(135, 201, 173);
$col-magenta: rgb(164, 3, 111);
$col-magenta-l: rgb(204, 64, 110);
$col-orange: rgb(181, 106, 8);
$col-yellow: rgb(214, 152, 52);
$col-darker: rgb(228, 229, 235);
$col-light-darker: rgb(236, 236, 241);

$col-toolbar-bg: #0D1B35;
$col-toolbar-bg-rgb: derive-rgb-values-from-hex(#0D1B35);
$col-light-blue: #6C7B99;
$col-light-blue-rgb: derive-rgb-values-from-hex(#6C7B99);
$col-white: #FFFFFF;
$col-white-rgb: derive-rgb-values-from-hex(#FFFFFF);
$col-black: #000000;
$col-black-rgb: derive-rgb-values-from-hex(#000000);
$col-gray: #f5f6f7;
$col-gray-rgb: derive-rgb-values-from-hex(#F5F6F7);
$col-gray-table: #757575;
$col-error: #f44336;
$col-red: #D02A4D;
$col-error-rgb: derive-rgb-values-from-hex(#f44336);
$col-light-gray: #D1D0D8;
$col-dark-gray: #727582;
$col-black-gray: #3F4752;
$col-yellow-light: #F1B210;

:root {
  --col-toolbar-bg: #{$col-toolbar-bg};
  --col-toolbar-bg-rgb: #{$col-toolbar-bg-rgb};
  --col-white: #{$col-white};
  --col-white-rgb: #{$col-white-rgb};
  --col-light-blue: #{$col-light-blue};
  --col-light-blue-rgb: #{$col-light-blue-rgb};
  --col-black: #{$col-black};
  --col-black-rgb: #{$col-black-rgb};
  --col-gray: #{$col-gray};
  --col-gray-rgb: #{$col-gray-rgb};
  --col-gray-table: #{$col-gray-table};
  --col-error: #{$col-error};
  --col-error-rgb: #{$col-error-rgb};
  --col-yellow: #{$col-yellow};
  --col-green: #{$col-green};
}

:export {
  violet: $col-violet;
  blue: $col-blue;
  blued: $col-blue-d;
  blued2: $col-blue-d2;
  bluel: $col-blue-l;
  green: $col-green;
  greenBright: $col-green-bright;
  greenLight: $col-green-light;
  magenta: $col-magenta;
  magental: $col-magenta-l;
  orange: $col-orange;
  yellow: $col-yellow;
  darker: $col-darker;
  toolbarbg: $col-toolbar-bg;
  lightblue: $col-light-blue;
  white: $col-white;
  black: $col-black;
  gray: $col-gray;
  graytable: $col-gray-table;
  error: $col-error;
  red: $col-red;
  yellowLight: $col-yellow-light;
}
