.form {
  width: 720px;
  display: grid;
  grid-template-rows: 1fr;
  grid-row-gap: 16px;
}

.autocomplete {
  margin-bottom: 0;
}

.twoCol {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 16px;
}

.button {
  margin-right: 20px;
}

.active {
  background: #fff;
}

.links {
  overflow: auto !important;
  max-height: 130px;
}
