.tableCell {
  color: rgba(0, 0, 0, 0.87) !important;
  padding: 16px;
}

.tableCellLarge {
  min-width: 200px;
  max-width: 450px;
}

.tableCellMedium {
  min-width: 170px;
  max-width: 250px;
}

.tableCellSmall {
  width: 100px;
}

.SelectInput {
  width: 100%;
  &>div {
    height: 32px;
  }
}

.warning {
  font-size: 25px;
}